<template>
  <div class="page-view">
    <!-- <van-nav-bar title="治理措施" left-arrow safe-area-inset-top @click-left="onBack(-1)" /> -->
    <div class="content-view">
      <div class="white-bg-view" style="padding: 4px 0">
        <van-form ref="formRef" :show-error="false" disabled scroll-to-error input-align="left" validate-trigger="onSubmit">
          <van-field
            :value="attendanceTypeMap[form.leaveType]?.attendancename"
            label="请假类型"
            right-icon="arrow-down"
            placeholder="请选择"
            required
            :rules="[{ required: true, message: '请选择请假类型' }]"
          >
            <template v-if="form.leaveType === '6'" #extra>
              <span style="margin-left: 10px;font-size: 10px;color: #999999">可使用公休天数:{{ day }}天</span>
            </template>
          </van-field>
          <van-field
            v-model="form.startTime"
            label="开始时间"
            readonly
            required
            :rules="[{ required: true, message: '请选择开始时间' }]"
          >
            <template #input>
              <van-field
                v-model="form.startTime"
                placeholder="开始时间"
                readonly
                style="padding: 0"
              />
            </template>
            <template #button @click.stop="showDropdown">
              <van-dropdown-menu active-color="#1778FF" style="margin-right: 10px">
                <van-dropdown-item v-model="form.startPoint" :options="option1" disabled @change="onDropChange" />
              </van-dropdown-menu>
            </template>
          </van-field>
          <van-field
            v-model="form.endTime"
            label="结束时间"
            readonly
            required
            :rules="[{ required: true, message: '请选择结束时间' }]"
          >
            <template #input>
              <van-field
                v-model="form.endTime"
                placeholder="结束时间"
                readonly
                disabled
                style="padding: 0"
              />
            </template>
            <template #button @click.stop="showDropdown">
              <van-dropdown-menu active-color="#1778FF" style="margin-right: 10px">
                <van-dropdown-item v-model="form.endPoint" :options="option1" disabled @change="onDropChange" />
              </van-dropdown-menu>
            </template>
          </van-field>
          <van-field
            v-model="form.leaveDay"
            label="请假时长"
            readonly
          >
            <template #extra>
              <span>天</span>
            </template>
          </van-field>
          <van-field
            v-model="form.extFiled1"
            label="请假事由"
            placeholder="请输入内容"
            type="textarea"
            rows="5"
            maxlength="200"
            show-word-limit
            readonly
            required
            :rules="[{ required: true, message: '请输入请假事由' }]"
          />
          <van-field
            v-model="form.leaveDate"
            label="申请时间"
            readonly
            placeholder="请选择"
          />
          <van-field name="uploader" label="佐证材料">
            <template #input>
              <div style="display: flex;flex-direction: column">
                <van-uploader
                  v-model="fileList"
                  preview-size="64"
                  :show-upload="false"
                  :deletable="false"
                  readonly
                />
              </div>
            </template>
          </van-field>
        </van-form>
      </div>
      <div v-if="workFLowTableData&&workFLowTableData.length" class="white-bg-view" style="padding:16px 12px;margin-top: 10px">
        <div v-for="(t,i) in workFLowTableData" :key="t.id" class="timeline-item">
          <div class="timeline-box">
            <img v-if="!i" src="@/assets/personal/icon_header0.png" style="width:30px;height:30px;" alt="icon">
            <img v-else src="@/assets/personal/icon_header1.png" style="width:30px;height:30px;" alt="icon">
            <div v-if="i!==workFLowTableData.length-1" class="long-line" />
          </div>
          <div class="timeline-content">
            <div class="flex-row" style="align-items:center;justify-content:space-between">
              <span style="font-size:15px;color:#03081A;font-weight:550;" class="flex-row flex-1">{{ t.nodeName }}</span>
              <span style="font-size:15px;font-weight:550;" class="flex-row" :class="t.shresult==='同意'?'main-color':t.shresult==='驳回'?'red-color':'default-color'">{{ t.shresult||'' }}</span>
            </div>
            <div class="flex-row" style="align-items:center;justify-content:space-between;margin-top: 10px">
              <span style="font-size:15px;color:#03081A;" class="flex-row flex-1">{{ t.optUserName }}</span>
              <span style="font-size:14px;color:#03081A;">{{ t.endTime ? t.endTime : '' }}</span>
            </div>
            <span v-if="t.shmsg" style="align-items:center;margin-top: 10px;font-size: 14px">{{ t.shmsg||'' }}</span>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showTypePicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="attendanceType"
        value-key="attendancename"
        @confirm="onTypeConfirm"
        @cancel="showTypePicker = false"
      />
    </van-popup>
    <van-popup v-model="showApprovalPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="approvalPersons"
        value-key="name"
        @confirm="onApprovalConfirm"
        @cancel="showApprovalPicker = false"
      />
    </van-popup>
    <van-calendar v-model="showCalendar" :formatter="formatter" first-day-of-week="1" color="#1778FF" @confirm="handleDateChange" />
  </div>
</template>

<script>
import { exportRequest, postRequest } from '@/utils/api'
import { blobType } from '@/utils/blobType'

export default {
  name: 'AddFrom',
  data() {
    return {
      id: '',
      detail: { },
      form: {
        id: '',
        remark: '', //
        leaveType: '',
        resource: '',
        leaveDay: 0,
        extFiled1: '', // 请假事由
        leaveDate: this.$dayjs().format('YYYY-MM-DD HH:mm:ss'),
        startTime: '', // 开始日期
        endTime: '', // 结束日期
        startPoint: 'AM', // 开始时刻
        endPoint: 'PM', // 结束时刻
        yearMonth: new Date().getFullYear(),
        delFlag: '1',
        approvalPerson: '',
        extFiled2: 'APP', // PC端请假
        extFiled3: '暂存',
        extFiled4: '', // InstanceID
        extFiled5: '' // taskID
      },
      fileList: [],
      showTypePicker: false, // 请假类型
      currentDate: new Date(),
      attendanceType: [],
      attendanceTypeMap: {
        '5': {
          attendancename: '事假'
        }
      },
      value1: 0,
      option1: [
        { text: '上午', value: 'AM' },
        { text: '下午', value: 'PM' }
      ],
      approvalPersons: [],
      showApprovalPicker: false,
      day: 0,
      showCalendar: false,
      showCalendarType: 'start',
      workFLowTableData: [],
      holiday: [], // 节假日列表
      holidayTag: [],
      lieuDay: [], // 周末调休日列表
      auditForm: {
        approvalMsg: '',
        approvalPerson: '',
        approvalPersonName: ''
      }
    }
  },
  computed: {
    userId() {
      return this.$store.getters.userId
    },
    userName() {
      return this.$store.getters.userName
    }
  },
  created() {
    this.getAttendanceType()
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
      this.getDetail()
      this.getFileInfo()
    }
    this.getHolidayList()
  },
  mounted() {
  },
  methods: {
    // 根据计划ID查询基础信息和隐患列表
    getDetail() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      const param = new FormData()
      param.append('id', this.id)
      postRequest('/pems/attendance/tAttendanceLeaveDate/findTAttendanceLeaveDateById', param).then(res => {
        this.$toast.clear()
        if (!res.data.successful) {
          return
        }
        this.form = res.data?.resultValue
        this.getProcess()
        this.getApprovalPersons()
        this.getUserGxLeaveDate()
      }).catch(() => {
        this.$toast.clear()
      })
    },
    // 获取请假类型
    getAttendanceType() {
      postRequest('/pems/attendance/tAttendanceData/findAttendanceType', {}).then(res => {
        if (!res.data.successful) {
          return
        }
        this.attendanceType = res.data.resultValue || []
        const typeMap = {}
        for (const item of this.attendanceType) {
          typeMap[item.id] = item
        }
        this.attendanceTypeMap = typeMap
      })
    },
    // 获取审批流程
    getProcess() {
      const form = new FormData()
      form.append('processInstanceId', this.form.extFiled4)
      postRequest('/pems/attendance/tAttendanceLeaveDate/getHistoryTaskList', form).then(res => {
        if (!res.data.successful) {
          return
        }
        this.workFLowTableData = res.data.resultValue || []
      })
    },
    // 获取审批人
    getApprovalPersons() {
      if (this.form.leaveDay > 1 && this.form.extFiled4 != null && this.form.extFiled3 === '主任审核') {
        postRequest('/pems/attendance/tAttendanceLeaveDate/getApprovalPersonsByParam', {
          types: 'RZZY',
          deptid: '68000004'
        }).then(res => {
          if (!res.data.successful) {
            return
          }
          this.approvalPersons = res.data.resultValue || []
          if (this.approvalPersons && this.approvalPersons.length) {
            this.auditForm.approvalPerson = this.approvalPersons[0].id
            this.auditForm.approvalPersonName = this.approvalPersons[0].name
          }
        })
        return
      }
      postRequest('/pems/attendance/tAttendanceLeaveDate/getApprovalPersons', {}).then(res => {
        if (!res.data.successful) {
          return
        }
        this.approvalPersons = res.data.resultValue || []
        if (this.approvalPersons && this.approvalPersons.length) {
          this.auditForm.approvalPerson = this.approvalPersons[0].id
          this.auditForm.approvalPersonName = this.approvalPersons[0].name
        }
      })
    },
    // 获取假期列表
    getHolidayList() {
      postRequest('/pems/system/holiday/getHolidayByParam', {
      }).then(res => {
        if (!res.data.successful) {
          return
        }
        const data = res.data.resultValue || []
        for (const item of data) {
          if (item.daystate === 1) {
            this.holiday.push(item)
            continue
          }
          if (item.daystate === 2) {
            this.lieuDay.push(item)
          }
        }
      })
    },
    // 获取公休剩余天数
    getUserGxLeaveDate() {
      postRequest('/pems/attendance/tAttendanceLeaveDate/getUserGxLeaveDate', {
        userId: this.form.userId,
        id: this.id || null
      }).then(res => {
        if (!res.data.successful) {
          return
        }
        this.day = res.data.resultValue || 0
      })
    },
    clickTime(type) {
      if (!this.form.id || this.form.extFiled3 === '暂存' || this.form.extFiled3 === '提交请假') {
        this.showCalendar = true
        this.showCalendarType = type
      }
    },
    formatter(day) {
      const date = this.$dayjs(day.date).day()
      const currentDate = this.$dayjs(day.date).format('YYYY-MM-DD')
      if (this.form.leaveType === 4 || this.form.leaveType === 7) {
        // 日期可选
        day.type = ''
      } else {
        // 周末
        if (date === 0 || date === 6) {
          day.topInfo = '休'
          day.type = 'disabled'
        }
        // 假期时间
        for (const item of this.holiday) {
          if (item.daystr === currentDate) {
            if (item.holidayname) {
              day.topInfo = item.holidayname
            } else {
              day.topInfo = item.daystatestr
            }
            day.type = 'disabled'
          }
        }
        // 调休上班时间
        for (const item of this.lieuDay) {
          if (item.daystr === currentDate) {
            day.topInfo = item.daystatestr
            day.type = ''
          }
        }
      }
      return day
    },
    handleDateChange(curDate) {
      console.log('selectDate===', curDate)
      if (this.showCalendarType === 'start') {
        this.form.startTime = this.$dayjs(curDate).format('YYYY-MM-DD')
      } else {
        this.form.endTime = this.$dayjs(curDate).format('YYYY-MM-DD')
      }
      this.getDateList()
      this.showCalendar = false
      if (this.form.leaveDay <= 5) {
        return
      }
      this.$dialog.confirm({
        title: '提示',
        message: '目前暂未开放5天及5天以内的线上请假流程',
        confirmButtonColor: '#1778FF'
      })
        .then(() => {
          this.form.leaveDay = 0
          this.form.startTime = ''
          this.form.endTime = ''
        })
    },
    onDropChange() {
      this.getDateList()
      if (this.form.leaveDay <= 5) {
        return
      }
      this.$dialog.confirm({
        title: '提示',
        message: '目前暂未开放5天及5天以内的线上请假流程',
        confirmButtonColor: '#1778FF'
      })
        .then(() => {
          this.dateVals = ''
          this.form.leaveDay = 0
          this.form.startTime = ''
          this.form.endTime = ''
        })
    },
    // 根据datePick区间，算出请假的日期list、请假天数
    getDateList() {
      this.dateList = []
      const startDate = new Date(this.form.startTime)
      const endDate = new Date(this.form.endTime)
      const formatHoliday = this.form.startTime
      if (this.form.leaveType !== 4 && this.form.leaveType !== 12) { // 事假等要排除正常放假的天数，然后计算实际请假日期
        while (endDate.getTime() - startDate.getTime() >= 0) {
          if (this.holiday.indexOf(formatHoliday) < 0) { // 排除holiday日期
            // 排除周末,但需要注意周末调休
            if ((startDate.getDay() !== 0 && startDate.getDay() !== 6) || this.lieuDay.indexOf(formatHoliday) > -1) {
              this.dateList.push(formatHoliday)
            }
          }
          startDate.setDate(startDate.getDate() + 1)
        }
      } else { // 病假、产假不考虑放假天数
        while (endDate.getTime() - startDate.getTime() >= 0) {
          this.dateList.push(formatHoliday)
          startDate.setDate(startDate.getDate() + 1)
        }
      }
      const nums = this.verifyDate()
      this.form.leaveDay = this.dateList.length + nums
      this.form.remark = this.dateList.toLocaleString()
    },
    verifyDate() {
      let val = 0
      if (this.form.startPoint === 'AM' && this.form.endPoint === 'PM') {
        val = 0
      } else if (this.form.startPoint === 'AM' && this.form.endPoint === 'AM') {
        val = -0.5
      } else if (this.form.startPoint === 'PM' && this.form.endPoint === 'PM') {
        val = -0.5
      } else if (this.form.startPoint === 'PM' && this.form.endPoint === 'AM') {
        val = -1
      }
      return val
    },
    onSubmit(isSubmit) {
      this.$refs['formRef'].validate().then(() => {
        // 提交
        if (isSubmit) {
          this.submit()
          return
        }
        // 保存
        if (!this.id) {
          this.saveMeasure()
        } else {
          // 更新
          this.updateMeasure()
        }
      }).catch(e => {
        console.log(e)
      })
    },
    afterRead(files) {
      console.log('afterRead==', files instanceof Array, files)
      files.name = files.file.name
    },
    async  uploadFile(file) {
      console.log('uploadFile==', file)
      file.status = 'uploading'
      file.message = '上传中...'
      const formData = new FormData()
      formData.append('files', file.file)
      formData.append('groupId', 'personleave')
      formData.append('businessId', this.id)
      formData.append('dataSource', 'MC')
      await postRequest('/pems/system/file/uploadFiles', formData).then((resp) => {
        console.log('上传图片', resp.data)
        if (resp.data.successful) {
          file.status = 'success'
          file.message = '上传成功'
          return
        }
        file.status = 'failed'
        file.message = '上传失败'
      }).catch(err => {
        console.log(err)
        file.status = 'failed'
        file.message = '上传失败'
      })
    },
    // 保存整改措施
    saveMeasure() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceLeaveDate/savePersonalLeaveData', {
        ...this.form
      }).then(async res => {
        this.$toast.clear()
        if (!res.data.successful) {
          this.$toast.fail('保存失败')
          return
        }
        this.id = res.data.resultValue
        if (this.fileList && this.fileList.length) {
          for (const fileItem of this.fileList) {
            if (fileItem.status === 'success') {
              continue
            }
            await this.uploadFile(fileItem)
          }
        }
        this.$toast.success('保存成功')
        this.onBack(-1)
      }).catch(() => {
        this.$toast.fail('保存失败')
      })
    },
    // 保存整改措施
    updateMeasure() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceLeaveDate/updatePersonalLeaveData', {
        ...this.form
      }).then(async res => {
        this.$toast.clear()
        if (!res.data.successful) {
          this.$toast.fail('保存失败')
          return
        }
        if (this.fileList && this.fileList.length) {
          for (const fileItem of this.fileList) {
            if (fileItem.status === 'success') {
              continue
            }
            await this.uploadFile(fileItem)
          }
        }
        this.$toast.success('保存成功')
        this.onBack(-1)
      }).catch(() => {
        this.$toast.fail('保存失败')
      })
    },
    // 提交流程
    submit() {
      if (!this.form.approvalPerson) {
        this.$toast('请选择审批人')
        return
      }
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 30000
      })
      postRequest('/pems/attendance/tAttendanceLeaveDate/submitPersonalLeaveData', {
        ...this.form
      }).then(res => {
        this.$toast.clear()
        if (!res.data.successful) {
          this.$toast.fail('提交失败')
          return
        }
        this.$toast.success('提交成功')
        this.onBack(-1)
      }).catch(() => {
        this.$toast.clear()
        this.$toast('提交失败')
      })
    },
    onBack(index) {
      this.$router.go(index)
    },
    onTypeConfirm(val) {
      console.log('confirm===', val)
      this.form.leaveType = val.id
      this.form.startTime = '' // 开始日期
      this.form.endTime = '' // 结束日期
      this.form.startPoint = 'AM' // 开始时刻
      this.form.endPoint = 'PM' // 结束时刻
      this.form.leaveDay = 0
      this.showTypePicker = false
    },
    onApprovalConfirm(val) {
      console.log('confirm===', val)
      this.auditForm.approvalPerson = val.id
      this.auditForm.approvalPersonName = val.name
      this.showApprovalPicker = false
    },
    showDropdown() {},
    // 获取附件信息
    getFileInfo() {
      postRequest('/pems/system/file/getFileInfo', {
        id: null,
        groupid: null,
        businessid: this.id
      }).then(res => {
        if (!res.data.successful) {
          return
        }
        const fileList = res.data.resultValue || []
        for (const item of fileList) {
          item.status = 'success'
          item.message = '上传成功'
          item.content = ''
          item.url = null
        }
        this.fileList = fileList
        this.getFiles(fileList)
      }).catch(() => {
      })
    },
    getFiles(fileList) {
      for (const item of fileList) {
        this.downloadFile(item.name, item.path, item)
      }
    },
    // 上传文件
    downloadFile(name, path, file) {
      exportRequest('/pems/system/file/downloadFile', {
        fileName: name,
        filePath: path
      }).then(res => {
        let fileType = 'png'
        if (name) {
          fileType = name.split('.')[1]
        }
        const blob = new Blob([res.data], {
          type: blobType[fileType]
        })
        console.log('blob===', blob)
        this.blobToBase64(blob).then(base64 => {
          file.content = base64
          this.$set(file, 'file', blob)
          console.log('file===', file)
          console.log('fileList===', this.fileList)
        })
      })
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.onerror = (e) => {
          reject(e)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page-view {
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  .content-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    background-color: #f5f6f9;

    .option-view{
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #03081A;
      font-weight: 550;
      padding: 10px 12px;
    }

    .white-bg-view{
      display: flex;
      flex-direction: column;
      background-color: white;
      padding: 8px 0;

      .tip-txt{
        margin: 8px 0;
        font-size: 12px;
        color: #999999;
        padding-left: 14px;
      }

      .cell-view{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding: 10px 12px;

        .label-txt{
          color: #838DA0;
          margin-right: 40px;
          width: 88px;
        }

        .value-txt{
          color: #03081A;
          display: flex;
          flex: 1;
          text-align: right;
          justify-content: flex-end;
        }
      }

      .file-list-view{
        display: flex;
        flex-direction: column;
        width: 100%;

        .file-item-view{
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #1778FF;
          padding: 2px 0;
          width: 100%;
          box-sizing: border-box;
          justify-content: space-between;
        }
      }

      .gray-bg-view{
        display: flex;
        flex-direction: column;
        background: #F6F6F6;
        border-radius: 8px;
        padding: 12px 15px;
        flex: 1;

        .value-txt{
          font-size: 16px;
          color: #03081A;
          margin-top: 10px;
          margin-left: 20px;
        }
      }
    }

    .button-view{
      display: flex;
      column-gap: 20px;
      margin-top: 20px;
      padding: 0 12px;
      margin-bottom: 50px;

      .add-btn{
        flex: 1;
        background-color: white;
        border-radius: 8px;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }

      .add-btn-primary{
        display: flex;
        flex: 1;
        background: #1778FF;
        border-radius: 8px;
        padding: 10px 0;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: white;
      }

    }

    .bottom-btn-view{
      display: flex;
      justify-content: space-between;
      padding: 15px 20px 0 20px;
      border-top: 1px solid #ebedf0;
      column-gap: 10px;
    }
  }
}
/* 时间线 */
.timeline-item {
  position: relative;
  display: flex;
  height: auto;
  width: 100%;

  .timeline-box {
    text-align: center;
    position: absolute;
    height: 100%;

    .long-line {
      width: 1px;
      height:calc(100% - 50px);
      border-left: 1px dashed #1778FF;
      margin-left: 15px;
    }
  }

  .timeline-content {
    box-sizing: border-box;
    margin-left: 20px;
    min-height: 60px;
    padding: 5px 0 0 20px;
    text-align: left;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .main-color{
      color: #1778FF;
    }
    .red-color{
      color: red;
    }
    .default-color{
      color:#03081A;
    }
  }

  .user-view {
    height: 28px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #03081A;
    padding: 2px 12px 2px 2px;
    background: #F2F3F5;
    border-radius: 14px;
    align-self: baseline;
    margin-top: 5px;
  }

}

.timeline-item:last-of-type .timeline-content {
  margin-bottom: 0;
}

::v-deep .van-dropdown-menu__bar{
  height: 100%;
  -webkit-box-shadow: none;
  box-shadow:none;
}
::v-deep .van-dropdown-menu__title{
  padding: 0 20px;
}
::v-deep .van-dropdown-menu__title::after{
  border-color: transparent transparent #333333 #333333;
}
</style>
